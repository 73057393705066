import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Hall({ styles, editing, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("content_text").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
          className="formkit-bg-color"
        />
        <div
          style={styles.forElement("background")}
          className="formkit-bg-image"
        />
        <div
          className={`formkit-image-container ${
            editing ? "formkit-inherit" : "formkit-fixed"
          }`}
        >
          <img
            className="formkit-record"
            src="https://embed.filekitcdn.com/e/ob1LxuDzufvKjqSohJyaUP/v81WSx4aVQrgzFfFapqWSF"
            width="475"
            height="475"
          />
          <Elements.Image
            className="formkit-record-insert"
            name="record_image"
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/fBTNvcvvyquwJDPFYzTT16"
            }}
            aspectRatio={1}
            shape="circle"
          />
          <div className="formkit-hole-relative">
            <div className="formkit-hole" />
          </div>
        </div>
        <div className="formkit-content-container">
          <Elements.Image
            className="formkit-logo"
            name="logo_image"
            defaults={{
              src:
                "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/iuEapYeVs9QM8THLRK3tnw"
            }}
            size={{ w: 100, h: 40 }}
          />
          <Elements.Heading
            className="formkit-preheading"
            name="preheading"
            tag="h2"
            defaults={{ content: "New music" }}
          />
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{ content: "New Album" }}
          />
          <Elements.Region className="formkit-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "<p>Sign up to be notified about the release of the first full studio album from Alecia Boston, When Strong Streets Shout.</p>"
              }}
            />
          </Elements.Region>
          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields
              style={{
                color: checkboxes_color
              }}
            >
              <Elements.AddFieldButton />
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Subscribe" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            className="formkit-disclaimer"
            name="disclaimer"
            defaults={{
              content:
                "<p>We respect your privacy. Unsubscribe at any time.</p>"
            }}
          />
          <Elements.BuiltWith background="background" image="background" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Hall, { name: "Hall" })
